<template>
    <div class="outer">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path d="M12 2.5C13.8789 2.5 15.7157 3.05717 17.2779 4.10104C18.8402 5.14491 20.0578 6.62861 20.7769 8.36451C21.4959 10.1004 21.684 12.0105 21.3175 13.8534C20.9509 15.6962 20.0461 17.3889 18.7175 18.7175C17.3889 20.0461 15.6962 20.9509 13.8534 21.3175C12.0105 21.684 10.1004 21.4959 8.36451 20.7769C6.62861 20.0578 5.14492 18.8402 4.10104 17.2779C3.05717 15.7157 2.50001 13.8789 2.50001 12C2.50265 9.48126 3.50439 7.06643 5.28541 5.28541C7.06644 3.50439 9.48126 2.50265 12 2.5ZM12 1C9.82441 1 7.69767 1.64514 5.88873 2.85383C4.07979 4.06253 2.66989 5.78049 1.83733 7.79048C1.00477 9.80047 0.786929 12.0122 1.21137 14.146C1.6358 16.2798 2.68345 18.2398 4.22183 19.7782C5.76021 21.3166 7.72022 22.3642 9.85401 22.7886C11.9878 23.2131 14.1995 22.9952 16.2095 22.1627C18.2195 21.3301 19.9375 19.9202 21.1462 18.1113C22.3549 16.3023 23 14.1756 23 12C23 9.08262 21.8411 6.28473 19.7782 4.22183C17.7153 2.15893 14.9174 1 12 1Z" fill="#162CA2"/>
        <path d="M12 17.25C14.8995 17.25 17.25 14.8995 17.25 12C17.25 9.10051 14.8995 6.75 12 6.75C9.10051 6.75 6.75 9.10051 6.75 12C6.75 14.8995 9.10051 17.25 12 17.25Z" fill="#162CA2"/>
        <path d="M12 7.5C12.89 7.5 13.76 7.76392 14.5001 8.25839C15.2401 8.75285 15.8169 9.45566 16.1575 10.2779C16.4981 11.1002 16.5872 12.005 16.4135 12.8779C16.2399 13.7508 15.8113 14.5526 15.182 15.182C14.5526 15.8113 13.7508 16.2399 12.8779 16.4135C12.005 16.5872 11.1002 16.4981 10.2779 16.1575C9.45566 15.8169 8.75286 15.2401 8.25839 14.5001C7.76392 13.76 7.5 12.89 7.5 12C7.50264 10.8073 7.9776 9.66428 8.82094 8.82094C9.66428 7.9776 10.8073 7.50264 12 7.5ZM12 6C10.8133 6 9.65328 6.35189 8.66658 7.01118C7.67989 7.67047 6.91085 8.60754 6.45673 9.7039C6.0026 10.8003 5.88378 12.0067 6.11529 13.1705C6.3468 14.3344 6.91825 15.4035 7.75736 16.2426C8.59648 17.0818 9.66558 17.6532 10.8295 17.8847C11.9933 18.1162 13.1997 17.9974 14.2961 17.5433C15.3925 17.0892 16.3295 16.3201 16.9888 15.3334C17.6481 14.3467 18 13.1867 18 12C18 10.4087 17.3679 8.88258 16.2426 7.75736C15.1174 6.63214 13.5913 6 12 6Z" fill="#162CA2"/>
        </svg>
    </div>
</template>

<script>
export default {
  name: 'BlueBulletIcon',
  props: {
    width: {
      type: Number,
      default: 25,
    },
    height: {
      type: Number,
      default: 25,
    },
  },
};
</script>

<style scoped>
.outer {
    display: flex;
    width: fit-content;
    height: fit-content;
    background-color: #F1F6FF;
    border-radius: 50%;
    padding: 5px;
}
</style>